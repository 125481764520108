import { ConsentCategory, CookieKey } from 'common/lib/Cookies/types';

export const necessaryCookies = [
  'ab_experiments', // set in simple-ab.js
  'auth_handler', // set in auth_handler.js middleware
  'CONSENTMGR', // consent cookie managed by tealium script, interaction via utag.gdpr
  'cookie_disclaimer_dismissed', // CH cookie banner
  'install_native_app_dismissed', // set for 14 days, after which users will be prompted again
  'login_reminder_dismissed', // login banner in dynamic application form
  'login_teaser', // set for 3 days either on logout or on teaser close
  'profile_teaser_application', // set for 1 day on "Maybe later" and 365 days on "Don't ask again"
  'salary_searches', // number of salary searches performed by the user
  'session_id', // uuid to identify a user session
  'showDebugMenu', // set manually by dev (no issue)
  'trigger_source', // source for tracking APIs
  'user_authenticated', // flag synced with user_token (httpOnly) cookie to signal a valid user session
  'user_email', // session cookie with the user's email to prefill forms on FE and onelog
  'user_token', // auth cookie
  'vacancy_search_teasers_dismissed', // dismissed vacancy search teasers
] as const;

export const consentCookies: { [key in ConsentCategory]: CookieKey[] } = {
  // functional
  analytics: [
    'recent_searches', // list of recent search queries
  ],
  // marketing
  search: [],
};

export const allowedCookies = (consentedCategories: ConsentCategory[]): CookieKey[] => {
  const allowedCookiesList: CookieKey[] = [
    ...necessaryCookies,
    ...consentedCategories.reduce<CookieKey[]>((prev, category) => [...prev, ...consentCookies[category]], []),
  ];

  return allowedCookiesList;
};
