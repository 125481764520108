import { QueryClient } from '@tanstack/react-query';
import sha256 from 'sha256';

import { DependencyInjection } from 'common/lib/DependencyInjection/services/services';
import { EventGlobals } from 'common/modules/tealium/utils/tealiumEvents';
import { TFunction } from 'common/modules/translation/lib/PolyglotCopy';
import { tenant } from 'common/util/configHandler';
import { Tenant } from 'common/util/configTypes';
import { pushToNewTask } from 'common/util/webVitals';

import { queryKey } from 'common/modules/auth/hooks/useCurrentUser';

import { User } from '@sentry/types/types-ts3.8/user';
import { TrackableEvent } from 'common/modules/tracking/types';

import tealiumTracking from 'common/modules/tealium/tealiumTracking';
import { ITranslationService } from 'common/modules/translation/services/TranslationService';
import { IReduxService } from 'common/stores/global';

export interface ITealiumService {
  track: (data: TrackableEvent, isView?: boolean) => Promise<void>;
}

type TealiumUserInfo = {
  currentUser?: User;
  notLoggedInUser?: string;
};

const availableNamespaces = [
  'company_segments_track',
  'employment_positions_track',
  'employment_types_track',
  'language_skills_track',
  'publication_dates_track',
  'regions',
  'regions_track',
];

export class TealiumService implements ITealiumService {
  private translationService: ITranslationService;
  private reduxService: IReduxService;
  private queryClient: QueryClient;
  private remoteTracking: (data: TrackableEvent & EventGlobals, isView?: boolean) => void;
  private tenant: Tenant;

  constructor(
    translationService: ITranslationService,
    reduxService: IReduxService,
    queryClient: QueryClient,
    remoteTracking: (data: TrackableEvent & EventGlobals, isView?: boolean) => void,
    tenant: Tenant
  ) {
    this.translationService = translationService;
    this.reduxService = reduxService;
    this.queryClient = queryClient;
    this.remoteTracking = remoteTracking;
    this.tenant = tenant;
  }

  track = async (data: TrackableEvent, isView?: boolean | undefined) => {
    const cachedStateAtTheTimeOfTrigger: TealiumUserInfo = {
      currentUser: this.queryClient.getQueryData<User>(queryKey),
      notLoggedInUser: this.reduxService.getState().auth?.notLoggedInUser as string,
    };
    await pushToNewTask('background');
    const values = Object.values(data);
    const requiredNamespaces = availableNamespaces.filter((namespace) =>
      values.some((value) => hasNameSpace(value, namespace))
    );
    await this.translationService.loadNamespaces(requiredNamespaces.map((namespace) => 'pools/' + namespace));
    const eventData = Object.keys(data).reduce(
      (curr, next) => ({
        ...curr,
        [next]: translateItem(data[next as keyof TrackableEvent] as string, (v) => this.translationService.t(v)),
      }),
      {} as TrackableEvent
    );

    await pushToNewTask('background');
    this.remoteTracking({ ...getGlobals(this.tenant, cachedStateAtTheTimeOfTrigger), ...eventData }, isView);
  };
}

export const hasNameSpace = (
  value: string | number | string[] | number[] | string[][] | number[][],
  namespace: string
) => {
  if (!value) return false;
  if (Array.isArray(value)) {
    return value.some((item) => item.toString().startsWith(namespace + '.'));
  }
  return value.toString().startsWith(namespace + '.');
};

export const translateItem = (value: string | number | string[] | number[] | string[][] | number[][], t: TFunction) => {
  const hasToBeTranslated = availableNamespaces.some((namespace) => hasNameSpace(value, namespace));
  if (!hasToBeTranslated) return value;
  if (Array.isArray(value)) {
    return value.map((item: any) =>
      Array.isArray(item)
        ? item.map((subItems) =>
            availableNamespaces.some((namespace) => hasNameSpace(subItems, namespace)) ? t(subItems) : subItems
          )
        : t(item)
    );
  }
  return t(value as string);
};

export const getGlobals = (tenant: Tenant, userInfo?: TealiumUserInfo): EventGlobals => ({
  event_origin: `${tenant}.ch`,
  event_source: 'application',
  user_audience: userInfo?.currentUser?.audience || '',
  user_id: (userInfo?.currentUser?.email && sha256(userInfo?.currentUser.email)) || '',
  user_login_id: userInfo?.currentUser?.uuid || userInfo?.notLoggedInUser || '',
  user_type: 'b2c',
});

const testTracking = (data: TrackableEvent) => {
  if (typeof window === 'undefined') {
    return;
  }
  if (!(window as any).tealiumTestData) {
    (window as any).tealiumTestData = [];
  }
  (window as any).tealiumTestData = [...(window as any).tealiumTestData, data];
};

export const TealiumServiceFactory = (di: DependencyInjection) => {
  const hasSafeDefaults = di.get('core.config').USE_SAFE_DEFAULTS;
  const hasDebugMenu = di.get('core.cookie').get('showDebugMenu');

  return new TealiumService(
    di.get('core.translation'),
    di.get('core.redux'),
    di.get('query.client'),
    async (data, isView) => {
      (hasSafeDefaults || hasDebugMenu) && testTracking(data);
      !hasSafeDefaults && (await tealiumTracking({ ...data }, isView)); // Tealium modifies the object
    },
    tenant.get()
  );
};
