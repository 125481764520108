import { sanitizeUrl } from '@braintree/sanitize-url';
import { cva } from 'panda/css';
import { HTMLStyledProps, styled } from 'panda/jsx';
import { FC, forwardRef } from 'react';
import { Link as RR6Link } from 'react-router-dom';

import { NavigationTarget } from 'common/lib/Routing/types';
import { getFullPath } from 'common/lib/Routing/utils/pathUtils';
import { LinkClickEvent } from 'common/modules/tealium/utils/tealiumEvents';

import useLocale from 'common/hooks/useLocale';
import { useTealiumLinkClick } from 'common/modules/tealium/hooks/useTealiumClick';

import { InteractiveSpan, InteractiveSpanProps } from 'common/ui/base';
import { getEventType } from 'common/ui/base/links/linkUtils';

interface SpecificLinkProps {
  linkColor?: 'brand' | 'dark' | 'white';
  underline?: 'full' | 'hover' | 'none';
  visited?: 'default' | 'none';
}

export interface LinkProps extends Omit<HTMLStyledProps<'a'>, 'css'>, SpecificLinkProps {
  buttonWrap?: 'button';
  customEventData?: Partial<LinkClickEvent>;
  to: NavigationTarget;
  state?: Record<string, unknown>;
}

export interface LinkExternalProps extends Omit<HTMLStyledProps<'a'>, 'css'>, SpecificLinkProps {
  buttonWrap?: 'button';
  customEventData?: Partial<LinkClickEvent>;
  to?: NavigationTarget;
}

export interface LinkSpanProps extends InteractiveSpanProps, SpecificLinkProps {}

const linkBase = cva({
  base: {
    cursor: 'pointer',
    transitionDuration: 'd125',
    transitionProperty: 'color',
    transitionTimingFunction: 'ease-out',
  },
  variants: {
    buttonWrap: {
      button: { display: 'inline-block', borderRadius: 'r32', textDecoration: 'none' },
    },
    underline: {
      full: { textDecoration: 'underline' },
      hover: { textDecoration: { base: 'none', _hover: 'underline' } },
      none: { textDecoration: 'none' },
    },
    visited: {
      default: { color: { base: 'colorPalette.base', _hover: 'colorPalette.hover', _visited: 'colorPalette.visited' } },
      none: { color: { base: 'colorPalette.base', _hover: 'colorPalette.hover' } },
    },
  },
  defaultVariants: { underline: 'hover', visited: 'default' },
});

export const A = styled('a');

const StyledLink = styled(RR6Link, linkBase);
export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ linkColor = 'brand', onClick, to, customEventData, ...rest }, ref) => {
    const locale = useLocale();
    const path = to && getFullPath(to, locale);
    const tealiumLinkClick = useTealiumLinkClick({ event_type: 'internal_link', ...customEventData });

    return (
      <StyledLink
        colorPalette={`link.${linkColor}`}
        onClick={tealiumLinkClick(onClick)}
        onContextMenu={tealiumLinkClick()}
        ref={ref}
        to={path}
        {...rest}
      />
    );
  }
);

Link.displayName = 'Link';

const StyledLinkExternal = styled('a', linkBase);
export const LinkExternal = forwardRef<HTMLAnchorElement, LinkExternalProps>(
  ({ href, linkColor = 'brand', onClick, to, customEventData, ...rest }, ref) => {
    const locale = useLocale();
    const linkHref = sanitizeUrl(!!to ? getFullPath(to, locale) : href);

    const tealiumLinkClick = useTealiumLinkClick({ event_type: getEventType(linkHref), ...customEventData });

    return (
      <StyledLinkExternal
        colorPalette={`link.${linkColor}`}
        href={linkHref}
        onClick={tealiumLinkClick(onClick)}
        onContextMenu={tealiumLinkClick()}
        ref={ref}
        {...(rest.target === '_blank' ? { rel: 'noopener nofollow' } : {})}
        {...rest}
      />
    );
  }
);

LinkExternal.displayName = 'LinkExternal';

const StyledLinkSpan = styled(InteractiveSpan, linkBase);
export const LinkSpan = forwardRef<HTMLSpanElement, LinkSpanProps>(({ linkColor = 'brand', ...rest }, ref) => (
  <StyledLinkSpan colorPalette={`link.${linkColor}`} ref={ref} {...rest} />
));

LinkSpan.displayName = 'LinkSpan';

export type LinkVariantProps =
  | ({ variant?: 'router' } & LinkProps)
  | ({ variant: 'external' } & LinkExternalProps)
  | ({ variant: 'span' } & LinkSpanProps);

export const LinkVariant: FC<LinkVariantProps> = ({ variant, ...rest }) => {
  switch (variant) {
    case 'external':
      return <LinkExternal {...(rest as LinkExternalProps)} />;
    case 'span':
      return <LinkSpan {...(rest as LinkSpanProps)} />;
    default:
      return <Link {...(rest as LinkProps)} />;
  }
};

export default Link;
